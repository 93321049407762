import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { signOut as firebaseSignOut, getAuth, onAuthStateChanged } from "firebase/auth";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyB5SmzwfRPTZUM3qfQCPo1pqhz8sHMrTgc",
  authDomain: "titanium-diode-418307.firebaseapp.com",
  projectId: "titanium-diode-418307",
  storageBucket: "titanium-diode-418307.appspot.com",
  messagingSenderId: "451315736773",
  appId: "1:451315736773:web:98831b2c30eafe37b1d0ac",
};

let firebaseApp: FirebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

function getFirebaseApp() {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
    const unsubscribe = onAuthStateChanged(auth, () => {
      unsubscribe();
    });
  }

  return firebaseApp;
}

export function useFirebaseUser() {
  return auth ? auth.currentUser : null;
}

export async function signOut() {
  await firebaseSignOut(getAuth(getFirebaseApp()));
}

/** This supports notification sending */
export const messaging = async () => {
  const supported = await isSupported();
  return supported ? getMessaging(firebaseApp) : null;
};

/** This is for push notifications */
export const fetchToken = async () => {
  try {
    const fcmMessaging = await messaging();
    if (fcmMessaging) {
      const token = await getToken(fcmMessaging, {
        vapidKey: process.env.NEXT_PUBLIC_FIREBASE_FCM_VAPID_KEY,
      });
      return token;
    }
    return null;
  } catch (err) {
    console.error("An error occurred while fetching the token:", err);
    return null;
  }
};

export default getFirebaseApp;
